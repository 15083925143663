body {
    padding: 0;
    margin: 0;
    background-color: #200000;
    color: white;
    font-family: 'DM Mono', monospace;
    background-image: url('WEB3.png');
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-position: center top;
}

.card-list {
    display: flex;
    padding: 3rem;
    overflow-x: scroll;
}

.card-list::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
.card-list::-webkit-scrollbar-thumb {
    background: #201c29;
    border-radius: 10px;
    box-shadow: inset 2px 2px 2px hsla(0,0%,100%,.25), inset -2px -2px 2px rgba(0,0,0,.25);
}

.card-list::-webkit-scrollbar-track {
    background: linear-gradient(90deg,#201c29,#201c29 1px,#17141d 0,#17141d);
}

.beets-card {
  background-image: url("/src/beets_token_250.png") !important;
}

.entry-card {
  background-image: url("/src/frame_grey_wood_250.png") !important;
}


.card {
    height: 350px;
    width: 250px;
    min-width: 250px;
    padding: 4px;
    border-radius: 16px;
    background: #fff;
    box-shadow: -1rem 0 1rem #000;
    display: flex;
    flex-direction: column;
    transition: .2s;
    margin: 0;
    scroll-snap-align: start;
    clear: both;
    position: relative;
}

.card:focus-within~.card, .card:hover~.card {
    transform: translateX(130px);
}

.card:hover {
    transform: translateY(-1rem);
}

.card:not(:first-child) {
    margin-left: -130px;
}

.unity-container {
  width:1200px;
  height:540px;
}

#loading {
  background: url('../src/images/loading.gif') no-repeat center center;
  height: 100%;
  width: 100%;
  z-index: 9999999;
}

.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
